<template>
	<section id="pricing" class="py-15">
		<v-container fluid>
			<p class="text-h4 text-center font-weight-bold text-uppercase mb-2">
				{{ $t('landing.pricesTitle') }}
			</p>
			<p class="text-h6 text-center mx-auto" style="max-width: 800px">
				{{ $t('landing.pricesText') }}
				<i18n path="landing.pricesTextYear" v-if="period.key == 'yearly'" tag="span">
					<template #toogle>
						<a @click="tooglePeriod()">{{ $t('landing.pricesToogleMonth') }}</a>
					</template>
				</i18n>
				<i18n path="landing.pricesTextMonth" v-if="period.key == 'monthly'" tag="span">
					<template #toogle>
						<a @click="tooglePeriod()">{{ $t('landing.pricesToogleYear') }}</a>
					</template>
				</i18n>
			</p>

			<SubscriptionPricingList :period="period.key" />
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandingPricing',
	data() {
		return {
			period: {
				key: 'yearly',
				name: 'Yearly'
			},
			periodList: {
				year: {
					key: 'yearly',
					name: 'Yearly'
				},
				month: {
					key: 'monthly',
					name: 'Monthly'
				}
			}
		}
	},
	components: {
		SubscriptionPricingList: () => import('@/components/subscriptions/SubscriptionPricingList.vue')
	},
	methods: {
		tooglePeriod() {
			if (this.period.key == 'yearly') this.period = this.periodList.month
			else if (this.period.key == 'monthly') this.period = this.periodList.year
			else this.period = this.periodList.year
		}
	}
}
</script>
